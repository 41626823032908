import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_c("a-modal", { attrs: { "after-close": _vm.afterModalClose, "title": "Copy Budget Exchange Rate" }, scopedSlots: _vm._u([{ key: "footer", fn: function() {
      return [_c("a-button", { on: { "click": _vm.cancel } }, [_vm._v("Cancel")]), _c("a-button", { attrs: { "loading": _vm.isLoading, "type": "primary" }, on: { "click": function($event) {
        return handleSubmit(_vm.onCopyClick);
      } } }, [_vm._v("Copy")])];
    }, proxy: true }], null, true), model: { value: _vm.visible, callback: function($$v) {
      _vm.visible = $$v;
    }, expression: "visible" } }, [_c("a-row", { attrs: { "gutter": 24 } }, [_c("a-col", { attrs: { "span": 6 } }, [_c("select-input", { attrs: { "label": "Country", "reference": "web-analytics.common.ppv-countries", "placeholder": "Select Country", "source": "countryId", "source-label": "countryCode", "value": _vm.country, "disabled": true, "rules": "required", "span": 12, "form-item": "", "on-select-change": _vm.onCountryChange } })], 1), _c("a-col", { attrs: { "span": 9 } }, [_c("date-picker", { attrs: { "year-only": true, "label": "from Fin Year", "format-date": "YYYY", "rules": "required", "form-item": "" }, on: { "change": function($event) {
      return _vm.storeValue("fromFinYear", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 9 } }, [_c("date-picker", { attrs: { "year-only": true, "label": "to Fin Year", "format-date": "YYYY", "rules": "required", "form-item": "" }, on: { "change": function($event) {
      return _vm.storeValue("toFinYear", $event);
    } } })], 1)], 1)], 1)];
  } }]) });
};
var staticRenderFns$1 = [];
var CopyBudgetExchangeRates_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => "")();
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "CopyBudgetExchangeRates",
  inject: ["resourceProps"],
  data() {
    const [budgetExchangeRatesProps, copyProps] = this.resourceProps;
    return {
      apiUrl: apiUrl$1,
      visible: false,
      isLoading: false,
      budgetExchangeRatesProps,
      copyProps,
      country: ""
    };
  },
  mounted() {
    this.visible = true;
  },
  created() {
    this.country = this.budgetExchangeRatesProps.crud.getQueryString("CountryID").value;
    this.storeValue("CountryId", this.country);
  },
  methods: {
    onCountryChange(value) {
      this.country = value;
      this.storeValue("CountryId", this.country);
    },
    storeValue(resourceKey, value) {
      const createForm = this.createForm;
      this.createForm = {
        ...createForm,
        [resourceKey]: value
      };
      this.copyProps.crud.updateEntity("createForm", {
        resourceKey,
        value
      });
    },
    cancel() {
      this.visible = false;
    },
    afterModalClose() {
      this.$router.push(this.copyProps.redirectRoute);
    },
    onCopyClick() {
      this.isLoading = true;
      this.copyProps.crud.submitEntity("create").then(() => {
        this.isLoading = false;
        this.visible = false;
        this.budgetExchangeRatesProps.crud.fetchList();
      }).catch(() => this.isLoading = false);
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "761e5d4a", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CopyBudgetExchangeRates = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", { attrs: { "name": "web-analytics.common.ppv-countries", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": ["web-analytics.budget-exchange-rates", "web-analytics.budget-exchange-rates.copy"], "api-url": _vm.apiUrl, "redirect-route": ["/web-analytics/budget-exchange-rates", "/web-analytics/budget-exchange-rates"] } }, [_c("copy-budget-exchange-rates")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    CopyBudgetExchangeRates
  },
  data() {
    return {
      CopyBudgetExchangeRates,
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
